@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap");

// Reset css
* {
  padding: 0;
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
}
html,
body {
  font-family: "Lexend Deca", sans-serif;
  font-size: 16px;
  background: #fff;
}
button {
  color: inherit;
  all: unset;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}

.menu li {
  font-weight: 600;
}

.menu li button {
  font-weight: 600;
}

.submenu {
  display: flex;
  flex-direction: column;
}

.submenu a {
  padding: 13px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s linear;
  &:hover {
    span {
      color: #006bfa;
    }
  }
  &:first-child {
    margin-top: 13px;
  }
}

.active-drop {
  transform: rotate(-180deg);
}

.submenu a img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.submenu a span {
  color: #575d6b;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

// App
.App {
  overflow: hidden;
  .container {
    margin: 0 auto;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .page-main {
    .section-intro {
      // background-image: url(assets/images/img-bg-1.png);
      // background-repeat: no-repeat;
      // background-position: center;
      // background-size: cover;
      min-height: 900px;
      position: relative;
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .page-header {
          display: flex;
          justify-content: center;
          // margin-top: 41px;
          z-index: 999;
          position: absolute;
          top: 41px;
          left: 0;
          right: 0;
          .nav-menu {
            background: #fff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 10px 29px 0px rgba(4, 12, 31, 0.05);
            padding: 20px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 1300px;
            width: 100vw;
            @media only screen and (max-width: 1480px) {
              max-width: 1200px;
            }
            @media only screen and (max-width: 1280px) {
              max-width: 960px;
            }
            @media only screen and (max-width: 1024px) {
              max-width: 860px;
            }
            @media only screen and (max-width: 948px) {
              // flex-direction: column;
              gap: 12px;
              max-width: 660px;
              padding: 16px;
            }
            @media only screen and (max-width: 748px) {
              max-width: 480px;
            }

            .img-logo {
              width: 233px;
              display: block;

              @media only screen and (max-width: 948px) {
                display: none;
              }
            }
            .img-logo-sp {
              width: 42px;
              display: none;

              @media only screen and (max-width: 948px) {
                display: block;
              }
              @media only screen and (max-width: 748px) {
                padding-left: 12px;
              }
            }
            color: #0f1928;
            ul {
              display: flex;
              gap: 48px;
              @media only screen and (max-width: 1024px) {
                gap: 24px;
              }
              @media only screen and (max-width: 948px) {
                display: none;
              }
              @media only screen and (max-width: 480px) {
                gap: 12px;
              }
              li {
                display: flex;
                align-items: center;
                gap: 4px;
                align-items: center;
                cursor: pointer;
                position: relative;
                transition: all 0.3s linear;
                &:hover {
                  color: #006bfa;
                  // svg {
                  //   fill: #006bfa;
                  // }
                }
                svg {
                  transition: all 0.2s linear;
                }
                a:hover {
                  opacity: 0.7;
                }
                .dropdown-menu {
                  list-style: none;
                  position: absolute;
                  top: 78px;
                  left: 0;
                  background-color: #fff;
                  box-shadow: 0px 10px 29px 0px rgba(4, 12, 31, 0.05);
                  padding: 10px 0;
                  display: none;
                  z-index: 999;
                  min-width: 260px;
                  li {
                    padding: 8px 15px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    img {
                      width: 24px;
                    }
                    a {
                      text-decoration: none;
                      color: #575d6b;
                    }
                  }
                }
              }
            }
            .btn-action {
              color: #fff;
              background: #006bfa;
              border-radius: 30px;
              padding: 14px 35px;
              &:hover {
                opacity: 0.7;
              }
              @media only screen and (max-width: 480px) {
                padding: 12px 35;
              }
            }
            .right {
              display: flex;
              align-items: center;
              gap: 8px;
              .btn-menu {
                display: none;
                img {
                  width: 42px;
                }
                @media only screen and (max-width: 948px) {
                  display: block;
                }
                @media only screen and (max-width: 748px) {
                  padding-right: 12px;
                }
              }
            }
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          gap: 30px;
          width: 60%;
          padding-top: 120px;
          padding-left: 250px;
          @media only screen and (max-width: 1600px) {
            padding-left: 160px;
          }
          @media only screen and (max-width: 1400px) {
            padding-left: 100px;
          }
          @media only screen and (max-width: 1200px) {
            padding-left: 0;
            text-align: center;
            justify-content: center;
            align-items: center;
          }
          @media only screen and (max-width: 948px) {
            align-items: center;
            width: 100%;
          }
          @media only screen and (max-width: 568px) {
            // margin-top: 24px;
          }
          .main {
            margin-top: 80px;
            color: #0f1928;
            font-size: 65px;
            line-height: 78px;
            font-weight: 500;
            @media only screen and (max-width: 948px) {
              text-align: center;
              font-size: 55px;
            }
            @media only screen and (max-width: 824px) {
              font-size: 48px;
            }
            @media only screen and (max-width: 568px) {
              line-height: normal;
              font-size: 36px;
            }
            .line {
              position: relative;
              z-index: 99;
              img {
                width: 558px;
                position: absolute;
                bottom: -15px;
                left: 0;
                z-index: -1;
                @media only screen and (max-width: 568px) {
                  width: 358px;
                }
              }
            }
          }
          .sub {
            max-width: 700px;
            font-size: 20px;
            line-height: 32px;
            color: #575d6b;
            @media only screen and (max-width: 948px) {
              text-align: center;
            }
            @media only screen and (max-width: 824px) {
              font-size: 16px;
              line-height: normal;
            }
          }
          .actions {
            display: flex;
            gap: 28px;
            @media only screen and (max-width: 824px) {
              gap: 18px;
            }
            @media only screen and (max-width: 568px) {
              flex-direction: column;
              gap: 12px;
            }
            .btn-trade {
              text-align: center;
              background: linear-gradient(
                90deg,
                #3ccbb7 0%,
                #006bfa 51%,
                #3ccbb7 100%
              );
              padding: 21px 50px;
              color: #fff;
              border-radius: 30px;
              height: fit-content;
              @media only screen and (max-width: 824px) {
                padding: 18px 24px;
              }
              &:hover {
                opacity: 0.7;
              }
            }
            .btn-community {
              display: flex;
              align-items: center;
              gap: 4px;
              border: 1px solid #f1f1f1;
              padding: 21px 50px;
              border-radius: 30px;
              &:hover {
                opacity: 0.7;
              }
              img {
                width: 18px;
              }
            }
          }
          .list {
            display: flex;
            gap: 45px;
            @media only screen and (max-width: 568px) {
              gap: 18px;
            }
            @media only screen and (max-width: 480px) {
              flex-direction: column;
              // align-items: center;
            }
            li {
              display: flex;
              gap: 10px;
              align-items: center;
              img {
                width: 34px;
              }
              font-size: 14px;
              color: #92969e;
            }
          }
        }
        .intro-bg-1,
        .intro-bg-2,
        .intro-bg-3 {
          position: absolute;
        }
        .intro-bg-1 {
          width: 290px;
          top: 40%;
          left: -100px;
          @media only screen and (max-width: 600px) {
            width: 180px;
          }
        }
        .intro-bg-2 {
          width: 300px;
          bottom: -250px;
        }
        .intro-bg-3 {
          width: 41px;
          height: 41px;
          bottom: 39px;
          left: -20.5px;
        }
      }
      &-main {
        display: flex;
        gap: 30px;
        width: 100%;
        position: relative;
        @media only screen and (max-width: 1200px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .intro-right {
        position: relative;
        width: 40%;
        max-width: 770px;
        flex-shrink: 0;
        @media only screen and (max-width: 1200px) {
          width: 60%;
        }

        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
    .section-about {
      background-image: url(assets/images/img-bg-2.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .container {
        padding: 130px 24px 220px;
        @media only screen and (max-width: 768px) {
          padding: 40px 24px 220px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 50px;
          color: #fff;
          text-align: center;
          font-weight: 500;
          max-width: 592px;
          @media only screen and (max-width: 768px) {
            font-size: 36px;
            line-height: normal;
          }
          @media only screen and (max-width: 568px) {
            margin-top: 24px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 24px;
          }
        }
        .description {
          margin-top: 15px;
          color: #96a1b1;
          color: #fff;
          max-width: 652px;
          text-align: center;
          @media only screen and (max-width: 480px) {
            font-size: 14px;
          }
        }
        .list-card {
          margin-top: 40px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 25px;
          @media only screen and (max-width: 480px) {
            gap: 18px;
          }
          .card {
            padding: 35px 45px;
            box-sizing: border-box;
            border-radius: 20px;
            max-width: 410px;
            background: rgba(255, 255, 255, 0.06);
            display: flex;
            align-items: center;
            gap: 36px;
            @media only screen and (max-width: 768px) {
              padding: 24px 24px;
            }
            @media only screen and (max-width: 480px) {
              gap: 18px;
            }
            img {
              width: 64px;
              height: 64px;
              flex-shrink: 0;
            }
            .content {
              .title {
                text-align: left;
                font-size: 20px;
                color: #fff;
                margin-top: 0;
                @media only screen and (max-width: 480px) {
                  font-size: 14px;
                }
              }
              .des {
                margin-top: 9px;
                font-size: 14px;
                color: #96a1b1;
                @media only screen and (max-width: 480px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .btn-action {
          color: #fff;
          background: linear-gradient(
            90deg,
            #3ccbb7 0%,
            #006bfa 51%,
            #3ccbb7 100%
          );
          padding: 21px 50px;
          border-radius: 30px;
          margin-top: 50px;
          &:hover {
            opacity: 0.7;
          }
          height: fit-content;
          @media only screen and (max-width: 824px) {
            padding: 18px 24px;
          }
        }
      }
    }
    .section-information {
      background-image: url(assets/images/img-bg-3.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .container {
        display: flex;
        justify-content: center;
        overflow: visible;
        flex-direction: column;
        align-items: center;
        .frame-info {
          margin-top: -150px;
          max-width: 1280px;
          width: 100%;
          background: #f8f8f8;
          border-radius: 15px;
          .list-info {
            padding: 80px 0 110px;
            display: flex;
            justify-content: center;
            gap: 20px;
            @media only screen and (max-width: 1350px) {
              padding: 24px 0 110px;
            }
            @media only screen and (max-width: 986px) {
              flex-wrap: wrap;
              padding: 24px;
            }
            li {
              box-shadow: 0px 1px 4px 0px rgba(0, 20, 90, 0.1);
              background: #fff;
              border-radius: 15px;
              padding: 60px 50px;
              max-width: 260px;
              @media only screen and (max-width: 1280px) {
                padding: 24px;
              }
              @media only screen and (max-width: 986px) {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              img {
                width: 145px;
              }
              .content {
                .title {
                  font-size: 20px;
                  margin-bottom: 40px;
                  color: #0f1928;
                  @media only screen and (max-width: 986px) {
                    margin-bottom: 24px;
                    text-align: center;
                  }
                }
                .des {
                  margin-top: 20px;
                  color: #92969e;
                  display: flex;
                  gap: 12px;
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
        .info-content {
          margin-top: 130px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          @media only screen and (max-width: 667px) {
            margin-top: 60px;
          }
          .sub-des {
            max-width: 975px;
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            color: #0f1928;
            @media only screen and (max-width: 667px) {
              font-size: 24px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 18px;
            }
          }
          .value {
            background: linear-gradient(3.54deg, #3ccbb7 0%, #006bfa 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-size: 180px;
            text-align: center;
            @media only screen and (max-width: 1280px) {
              font-size: 120px;
            }
            @media only screen and (max-width: 848px) {
              font-size: 90px;
            }
            @media only screen and (max-width: 667px) {
              font-size: 60px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 48px;
            }
          }
          .note {
            font-size: 24px;
            font-weight: 500;
            color: #0f1928;
            text-align: center;
            @media only screen and (max-width: 480px) {
              font-size: 18px;
            }
          }
        }
      }
      .slider {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 100px;
        padding-bottom: 120px;
        @media only screen and (max-width: 480px) {
          margin-top: 80px;
          padding-bottom: 80px;
        }
        .marquee {
          display: flex;
          gap: 20px;
        }
      }
    }
  }
  .section-snipe {
    background-image: url(assets/images/img-bg-4.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .container {
      padding: 125px 24px;
      display: flex;
      justify-content: center;
      gap: 190px;
      @media only screen and (max-width: 1345px) {
        gap: 100px;
      }
      @media only screen and (max-width: 1280px) {
        gap: 48px;
      }
      @media only screen and (max-width: 1096px) {
        flex-wrap: wrap;
      }
      @media only screen and (max-width: 480px) {
        padding: 48px 24px;
      }
      .left {
        .title {
          color: #fff;
          font-size: 50px;
          max-width: 466px;
          line-height: 60px;
          @media only screen and (max-width: 1096px) {
            text-align: center;
          }
          @media only screen and (max-width: 696px) {
            font-size: 36px;
            line-height: normal;
          }
        }
        .subtitle {
          color: #96a1b1;
          margin-top: 30px;
          @media only screen and (max-width: 1096px) {
            text-align: center;
          }
        }
      }
      .right {
        display: flex;
        gap: 20px;
        @media only screen and (max-width: 696px) {
          flex-wrap: wrap;
          justify-content: center;
        }
        .card {
          padding: 32px 30px;
          background: #fff;
          border-radius: 20px;
          max-width: 308px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 27px;
          @media only screen and (max-width: 1280px) {
            padding: 24px;
          }
          .icon-snipe {
            width: 66px;
          }
          .title {
            font-size: 26px;
            color: #202026;
          }
          .des {
            font-size: 14px;
            color: #6b6b71;
            max-width: 242px;
          }
          .btn-detail {
            display: flex;
            align-items: center;
            gap: 12px;
            color: #202026;
            img {
              width: 21px;
            }
            span {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
  .section-referral {
    background-image: url(assets/images/img-bg-5.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .container {
      padding: 117px 24px;
      display: flex;
      @media only screen and (max-width: 968px) {
        justify-content: center;
      }
      @media only screen and (max-width: 480px) {
        padding: 48px 24px;
      }
      .content {
        padding-bottom: 100px;
        @media only screen and (max-width: 968px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 48px;
        }
        .title {
          color: #0f1928;
          font-size: 50px;
          line-height: 60px;
          font-weight: 500;
          @media only screen and (max-width: 968px) {
            text-align: center;
          }
          @media only screen and (max-width: 480px) {
            font-size: 36px;
            line-height: normal;
          }
          .line {
            position: relative;
            z-index: 99;
            img {
              width: 370px;
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: -1;
              @media only screen and (max-width: 968px) {
                width: 250px;
                left: 50%;
                transform: translateX(-50%);
              }
              @media only screen and (max-width: 480px) {
                width: 200px;
              }
            }
          }
        }
        .list-ref {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 32px;
          li {
            padding: 30px 38px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            border-left: 1px solid var(--color-grey-91, #e9e9e9);
            .des {
              max-width: 360px;
              color: #676672;
            }
            .sub-des {
              max-width: 360px;
              color: #a6a6aa;
            }
          }
        }
        .btn-ref {
          display: inline-block;
          margin-top: 32px;
          color: #fff;
          background: linear-gradient(
            90deg,
            #3ccbb7 0%,
            #006bfa 51%,
            #3ccbb7 100%
          );
          padding: 21px 50px;
          border-radius: 30px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .section-why {
    background: linear-gradient(
      180deg,
      #f8f8f8 0%,
      rgba(248, 248, 248, 0) 100%
    );
    .container {
      padding: 140px 24px 105px;
      display: flex;
      gap: 30px;
      align-items: center;
      overflow: visible;
      @media only screen and (max-width: 968px) {
        flex-direction: column;
        padding: 48px 24px;
      }
      .left {
        max-width: 415px;
        @media only screen and (max-width: 968px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .title {
          color: #0f1928;
          font-size: 50px;
          font-weight: 500;
          line-height: 60px;
          @media only screen and (max-width: 480px) {
            font-size: 36px;
            line-height: normal;
          }
        }
        .des {
          margin-top: 15px;
          color: #575d6b;
          font-size: 20px;
          line-height: 32px;
          @media only screen and (max-width: 480px) {
            font-size: 16px;
            line-height: normal;
          }
        }
        @media only screen and (max-width: 968px) {
          .title {
            text-align: center;
          }
          .des {
            text-align: center;
          }
        }
        .actions {
          display: flex;
          gap: 20px;
          margin-top: 32px;
          .btn-action {
            cursor: pointer;
            width: 54px;
          }
        }
      }
      .right {
        .list-answers {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          .card {
            box-shadow: 0px 1px 4px 0px rgba(0, 20, 90, 0.1);
            background: #fff;
            border-radius: 15px;
            padding: 55px;
            max-width: 524px;
            flex-shrink: 0;
            margin-bottom: 1px;
            @media only screen and (max-width: 667px) {
              padding: 24px;
              max-width: 240px;
            }
            .title {
              display: flex;
              flex-direction: column;
              gap: 8px;
              .title-1 {
                color: #0f1928;
                font-weight: 500;
              }
              .title-2 {
                color: #878c93;
                font-size: 12px;
              }
            }
            .des {
              margin-top: 16px;
              max-width: 414px;
              font-size: 18px;
              line-height: 30px;
              color: #6b6b71;
            }
            .icon {
              margin-top: 45px;
              margin-left: auto;
              width: 42px;
              @media only screen and (max-width: 667px) {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
  .page-footer {
    background-image: url(assets/images/img-bg-6.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .container {
      padding: 82px 24px 64px;
      display: flex;
      @media only screen and (max-width: 968px) {
        padding: 48px 24px;
      }
      .list-content {
        display: flex;
        width: 100%;
        justify-content: center;
        @media only screen and (max-width: 968px) {
          flex-direction: column;
          align-items: center;
          gap: 24px;
        }
        li {
          max-width: 260px;
          flex-shrink: 0;
          width: 100%;
          font-weight: 500;
          color: #fff;
          @media only screen and (max-width: 1300px) {
            max-width: 160px;
          }
          &:first-child {
            img {
              width: 171px;
            }
            @media only screen and (max-width: 1300px) {
              margin-right: 36px;
            }
            @media only screen and (max-width: 968px) {
              margin-right: 0;
            }
          }
          @media only screen and (max-width: 968px) {
            text-align: center;
          }
          .sub-menu {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            gap: 9px;
            a {
              color: #b3b9c2;
              font-weight: 400;
            }
          }
        }
      }
    }
    .copyright {
      background: linear-gradient(90.04deg, #3ccbb7 0%, #006bfa 100%);
      text-align: center;
      color: #fff;
      padding: 10px 0;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      @media only screen and (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
  .header-menu {
    background: #fff;
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    .btn-close {
      img {
        width: 45px;
      }
    }
    .menu {
      display: flex;
      flex-direction: column;
      padding: 25px 30px;
      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 14px;
        text-transform: uppercase;
        padding: 13px 0;
        font-weight: 600;
      }
    }
  }
}

.rfm-marquee {
  min-width: auto !important;
}
